.pass-reset-text{
    margin-top: 10%;

}

.pass-reset-container{
    text-align: left;
    width: 25%;
    height: 50vh;
    background-color: rgb(252, 252, 252);
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}

.pass-reset{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.input-Box-password-reset{
    min-width: 100%;
    height: 50px;
    box-sizing: border-box;
    margin: 5px;
    margin-top: 15px;
    padding: 25px;
    outline: none;
    border: none;
    background-color: aliceblue;
}

.reset-button{
    min-width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    border: none;
    background-color:rgb(4, 61, 2);
    color: white;
    font-weight: 600;  
    margin-top: 20px;  
}

@media only screen and (max-width: 768px) {
    .pass-reset-container{
        text-align: justify;
        width: 100%;
        height: 50vh;
        background-color: rgb(252, 252, 252);
        padding: 20px;
        border-radius: 5px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .pass-reset-container{
        text-align: justify;
        width: 100;
        height: 50vh;
        background-color: rgb(252, 252, 252);
        padding: 20px;
        border-radius: 5px;
        margin-top: 20px;
    }
}