.navbar-container {
  position: relative;
  padding: 10px;
  z-index: 1000;
}

.toggle-button {
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  font-size: larger;
  outline: none;
  border: none;
  background: none;
}

.nav-links {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: #044113;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: right 0.3s ease;
}

.nav-links.open {
  right: 0;
  color: white;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
}

.icon-nav-menu {
  font-size: 18pt;
  color: rgb(0, 0, 0);
}

.icon-nav-close {
  font-size: 18pt;
  color: rgb(255, 255, 255);

}
.share-box {
  max-width: 350px;
  padding: 20px;
  background-color: #f9f9f979;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  top: 60px;
  right: 10px;
  z-index: 1000;
}

.share-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.share-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;
}

.share-option:hover {
  color: #007bff;
}

.share-option img {
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
}

.share-url {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.copyedUrl{
  width: 100%;
  padding: 8px;
  border: 1px solid #13a306;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
  display: block; /* Ensure the hover area covers the full width of the link */
  transition: background-color 0.3s, color 0.3s;
}

.nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Add a light overlay effect */
  color: hsl(120, 20%, 95%); /* Change text color on hover */
  font-weight: bold; /* Optionally, make the text bold */
  border-radius: 4px; /* Add slight rounding to the highlight */
  padding: 10px; /* Maintain padding during hover */
}

.tab {
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.tab:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Change background color on hover */
  color: hsl(120, 20%, 95%); /* Change text color on hover */
  font-weight: bold; /* Optionally, make the text bold */
  border-radius: 4px; /* Add slight rounding to the highlight */
  padding: 10px; /* Maintain padding during hover */

}


