body {
    background-color: #f9f9f9;
}

.card {
    border-radius: 10px;
}

.card-title {
    font-weight: bold;
    color: #333;
}

button {
    font-size: 16px;
    font-weight: 600;
}

.card img {
    object-fit: cover;
    /* height: 150px; */
}

