/* Default (for larger screens) */
.item-searched-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 10px;
    min-height: 200px;
    cursor: pointer;
    transition: transform 0.5s ease;
}

.item-searched-card:hover {
    transform: scale(1.02);
    background-color: rgb(245, 245, 245);
}

.item-image {
    min-width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 20px;
    height: 300px;
    object-fit: cover;
}

.item-data {
    min-width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    height: 200px;
    padding: 20px;
    text-align: start;
}

.image-cover {
    width: 200px;
    height: 200px; /* Fixed height for the container */
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    overflow: hidden; /* Ensures any overflow is hidden */
    position: relative; /* Allows positioning of the img */
}

.des-box{
    background-color: aqua;
    width: 100%;
    height: 150px;
}

.descript{
    object-fit: cover;
}

/* Media query for tablets (max-width: 768px) */
@media (max-width: 768px) {
    .image-cover {
        width: 100%;
        height: 400px; /* Fixed height for the container */
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        overflow: hidden; /* Ensures any overflow is hidden */
        position: relative; /* Allows positioning of the img */
    }
    
    .item-image {
        width: 100%; /* Make image fill the width */
        height: 400px; /* Make image fill the height */
        object-fit: cover; /* This makes the image cover the container */
        object-position: center; /* Center the image */
    }
    
    .item-searched-card {
        flex-direction: column; /* Stack items vertically */
        min-height: 300px;      /* Increase height for better layout on small screens */
    }

    .image-container {
        width: 100%; /* Adjust to your needs */
        height: 150px; /* Set the desired height */
        overflow: hidden; /* Ensure no overflow */
        position: relative; /* Optional for additional positioning */
      }
      
    .item-data {
        padding: 15px;          /* Reduce padding */
    }

    .data-container{
        margin-top: 30%;
        display: flex;
        align-items: center;
        justify-content: left;
    }
}

/* Media query for mobile devices (max-width: 480px) */
@media (max-width: 480px) {
    .item-searched-card {
        padding: 10px;          /* Reduce padding for smaller screens */
        min-height: 250px;      /* Adjust the height */
    }

    .item-image {
        max-height: 100px;          /* Reduce image height */
    }

    .item-data {
        text-align: center;
        text-align: justify;
        padding: 10px;          /* Further reduce padding */
        font-size: 0.9rem;      /* Adjust font size for readability */
    }

    .item-data-container{
        flex-wrap: wrap;
        
    }

    .items-data-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: left;
    }
}

.items-data-container{
    display: flex;
    align-items: center;
    justify-content: left;
}

/* item.css */
.item-details {
    display: flex;
    flex-direction: column;
  }
  
  .item-detail {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }

  .item-detail1 {
   font-weight: bold;
   font-size: 16px;
  }
  
  .data-icon {
    font-size: 20px;
    color: #555;
  }
  
  .item-data {
    line-height: 1.2; /* Adjust value as needed */
  }

  .item-details p {
    margin: 0; /* Remove extra space between paragraphs */
    line-height: 1.6; /* Adjust value as needed */
  }
  
 
