.card {
    border-radius: 10px;
  }
  
  .card img {
    object-fit: cover;
  }
  
  /* .text-center {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border: 3px solid #007bff;
  } */
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-subtitle {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .user-profile h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .user-profile h4 {
    font-weight: 600;
  }
  
  .user-profile .card {
    border-radius: 12px;
  }
  
  .user-profile .card .btn {
    font-weight: 600;
  }
  