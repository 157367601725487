
/* searched-cards.css */
.d-flex {
    display: flex;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .item-card {
    width: calc(50% - 10px); /* This ensures 2 columns with a little space in between */
    margin-bottom: 20px; /* Adds space between rows */
  }
  
  .detailed-item-container {
    margin-bottom: 20px; /* Space between detailed search item and other items */
  }
  
  @media (max-width: 768px) {
    .item-card {
      width: 100%; /* On smaller screens, make it 1 column */
    }
  }
  




.searchBox-text-box {
    min-width: 550px;
    height: 70px;
    background-color: rgb(234, 247, 247);
    box-sizing: border-box;
    border-radius: 500px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 40px;
    font-size: larger;
    outline: none;
    border: none;
}

.searchBox-text-box-searched {
    min-width: 550px;
    height: 40px;
    background-color: rgb(234, 247, 247);
    box-sizing: border-box;
    border-radius: 500px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 40px;
    font-size: larger;
    outline: none;
    border: none;
}

.searchBox-bg {
    width: 70px;
    height: 70px;
    background-color: rgb(4, 65, 19);
    box-sizing: border-box;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    font-size: larger;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.searchBox-bg-searched {
    width: 60px;
    height: 40px;
    background-color: rgb(4, 65, 19);
    box-sizing: border-box;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    font-size: larger;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.navigate-search-box{
    top: 5.5%;
    left: 35%;
    position: absolute;
    z-index: 9999;
}

.icon {
    font-size: 18pt;
    color: white;
}

.search-group {
    height: auto;
}



@media only screen and (max-width: 768px) {
    .searchBox-text-box {
        min-width: 250px;
        height: 50px;
        font-size: medium;
        padding-left: 20px;
    }

    .searchBox-bg {
        width: 80px;
        height: 50px;
    }

    .icon {
        font-size: 14pt;
    }

    .search-group {
        height: auto;
        margin-top: 40%;
    }

    .searchBox-bg-searched {
        width: 80px;
        height: 50px;
    }

    .searchBox-text-box-searched {
        min-width: 250px;
        height: 50px;
        font-size: medium;
        padding-left: 20px;
    }

    .navigate-search-box{
        top: 0%;
        left: 0%;
        position: relative;
    }
    
}

@media only screen and (max-width: 480px) {

    .searchBox-text-box {
        height: 40px;
        font-size: small;
        padding-left: 15px;
        min-width: 250px;
    }

    .searchBox-bg {
        width: 60px;
        height: 40px;
        position: relative;
        margin-top: 0;
    }

    .icon {
        font-size: 12pt;
    }

    .search-group {
        height: auto;
        margin-top: 35%;
    }

    .fixed-search {
        margin-bottom: 10px;
        left: 0;
        width: 100%;
        padding: 10px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.5s ease-out;
    }

    /* .searchBox-bg-searched {
       
    }

    .searchBox-text-box-searched {
        
    } */
}

.fixed-search {
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
}

.searched-text {
    font-style: italic;

}

.searched-cards {
    height: auto;
   
}

.searchedArea {
    width: 100%;
    top: 300px;
}

.display-none {
    display: none;
}

.typing-effect {
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    /* border-right: 20px solid rgba(0, 0, 0, 1);  */
    width: 0;
    opacity: 1;
    font-family: sans-serif;
    animation: typing 2s steps(40, end) 1 forwards, hide-caret 0s 3s forwards;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes hide-caret {
    from {
        border-right: 2px solid rgba(0, 0, 0, 0.75);
    }

    to {
        border-right: none;
    }
}

@media (max-width: 768px) {
    .typing-effect {
        font-size: 1.25rem;
    }
}

@media (max-width: 480px) {
    .typing-effect {
        font-size: 1rem;
    }
}



/* detaild card */

.detailed-card {
    width: 100%;
    padding: 20px;
    margin: 20px auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin: 40px;


}

.image-area {
    gap: 10px;
    /* Space between images */
}

.collage-image {
    width: 100%;
    /* Full width of the grid cell */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 8px;
    /* Rounded corners */
}

.divider {
    background-color: rgb(158, 156, 156);
    width: 2px;
    height: auto;
    margin: 20px;

}

.details {
    width: 100%;
    padding: 10px;
    /* Inner spacing */
    text-align: left;

}

.item-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.item-description {
    font-size: 1rem;
    margin: 10px 0;
    color: #555;
    text-align: justify;
    width: 100%;
    display: flex;
    object-fit: cover;
}

.item-price {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 15px;
}

.go-to {
    color: white;
    background-color: #218838;
    width: 100%;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.add-to-cart:hover {
    background-color: #218838;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .image-area {
        grid-template-columns: 1fr;
        /* Stack images on small screens */
    }

    .item-title {
        font-size: 1.5rem;
        /* Smaller title */
    }

    .item-price {
        font-size: 1rem;
        /* Smaller price */
    }

    .add-to-cart {
        width: 100%;
        /* Full width button */
    }

    
}

.img1Cover {
    width: 500px; /* Fixed width */
    height: 250px; /* Fixed height */
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    overflow: hidden; /* Ensure overflow is hidden */
    position: relative; /* Allows positioning of the img */
}

.imgc1 {
    position: absolute; /* Use absolute positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    width: 100%; /* Stretch to full width */
    height: 100%; /* Stretch to full height */
    object-fit: cover; /* Cover to maintain aspect ratio */
    object-position: center; /* Center the image */
    transform: translate(-50%, -50%); /* Center the image */
}

.img2Cover,
.img3Cover {
    width: 50%; /* Shared styles for both covers */
    height: 150px; /* Fixed height */
    box-sizing: border-box;
    border-radius: 10px;
    margin: 5px;
    overflow: hidden; /* Hide overflow */
    position: relative; /* Allows positioning of the img */
}

.imgc2,
.imgc3 {
    position: absolute; /* Position the image absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    width: 100%; /* Stretch to full width */
    height: 100%; /* Stretch to full height */
    object-fit: cover; /* Cover to maintain aspect ratio */
    object-position: center; /* Center the image */
    transform: translate(-50%, -50%); /* Center the image */
}



@media only screen and (max-width: 768px) {
    .detailed-card {
        width: 100%;
        padding: 20px;
        margin: 20px auto;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        margin: 40px;
    }

    .image-area {
        width: 100%;
    }

    .img1Cover {
        width: 100%;
        height: 150px;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden; /* Ensures any overflow is hidden */
        position: relative; /* Allows positioning of the img */
    }
    
    .imgc1 {
        position: absolute; /* Use absolute positioning */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        width: 100%; /* Stretch to full width */
        height: 100%; /* Stretch to full height */
        object-fit: cover; /* Cover to maintain aspect ratio */
        transform: translate(-50%, -50%); /* Center the image */
    }
    
    .img2Cover {
        width: 50%;
        height: 150px;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden; /* Ensures any overflow is hidden */
        position: relative; /* Allows positioning of the img */
    }

    .imgc2 {
        position: absolute; /* Use absolute positioning */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        width: 100%; /* Stretch to full width */
        object-fit: cover; /* Cover to maintain aspect ratio */
        transform: translate(-50%, -50%); /* Center the image */
    }

    .img3Cover {
        width: 50%;
        height: 150px;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 5px;
        overflow: hidden; /* Ensures any overflow is hidden */
        position: relative; /* Allows positioning of the img */
    }

    .imgc3 {
        position: absolute; /* Use absolute positioning */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        width: 100%; /* Stretch to full width */
        object-fit: cover; /* Cover to maintain aspect ratio */
        transform: translate(-50%, -50%); /* Center the image */
    }
}

@media only screen and (max-width: 480px) {}

.h-100 {
    font-size: 15px;   
    font-family: Arial, sans-serif; 
}

.divider {
    font-size: 15px; 
    font-family: Arial, sans-serif;   
}

.item-description {
    font-size: 15px;  
    font-family: Arial, sans-serif;  
}
