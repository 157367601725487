
.table-view-doc {
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 600px;
    margin: 20px auto;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  .table-header {
    display: flex;
    background-color: rgb(4, 65, 19);
    color: white;
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .header-item {
    flex: 1;
    text-align: center;
    padding: 5px;
  }
  
  
  .table-body {
    background-color: #f9f9f9;
  }
  
  .table-row {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    transition: background-color 0.3s;
  }
  
  .table-row:hover {
    background-color: #f1f1f1;
  }
  
  
  .row-item {
    flex: 1;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    color: #333;
  }
 

.view-button {
    padding: 5px 10px;
    background-color: rgb(4, 65, 19);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .view-button:hover {
    background-color: rgb(4, 65, 19);
  }
  

  
  