.login-screen-container{
    margin-top: 10px;

}

.login-form{
    border-radius: 20px;
    text-align: center;
}

/* .form-data{
} */

.input-Box{
    width: 350px;
    height: 50px;
    box-sizing: border-box;
    margin: 5px;
    border-radius: 20px;
    padding: 25px;
    outline: none;
    border: none;
    background-color: aliceblue;
}

.login-button{
    width: 250px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    border: none;
    background-color:rgb(4, 61, 2);
    color: #faf8fc;
    font-weight: 600;    
}

.password-group {
    position: relative;
}

.eye-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
}

.password-group {
    position: relative;
}
