.modal-body {
  background-color: #f4fdf4;
}

.table-success th,
.table-success td {
  border: 1px solid #dee2e6;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
  border: 2px solid #28a745;
}

.modal-footer .btn-success {
  display: flex;
  align-items: center;
}

body {
  font-family: "Roboto", sans-serif;
}

.hero-section {
  position: relative;
  background-image: url('https://vistatec.com/wp-content/uploads/2024/03/Life-Sciences-In-Focus-Real-World-Evidence.png');
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  /* Text color */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  /* Optional text shadow for better readability */
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  /* Dark layer with 50% opacity */
  z-index: 1;
}

.hero-section .container {
  position: relative;
  z-index: 2;
  /* Ensure content is above the dark overlay */
}