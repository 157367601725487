.product-card {
    border-radius: 8px;
    transition: transform 0.3s;
    cursor: pointer;
    min-height: 450px;
    max-height: 450px;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .product-card img {
    height: 200px; /* Fixed height for uniformity */
    object-fit: cover; /* Cover to maintain aspect ratio */
  }
  
  .card-title {
    font-size: 1.2rem; /* Adjust the font size as needed */
    
  }
  
  .card-text {
    font-size: 1rem; /* Adjust the font size as needed */
  }
  
  .product-card.shadow {
    height: 450px; /* Sets all cards to the same fixed height */
}
