/* CenterOperations.css */
.card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 2rem;


  }
  
  .card h5 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  button {
    margin-top: 1rem;
  }

  
  .center-hero-section {
    position: relative;
    background-image: url('https://eu-images.contentstack.com/v3/assets/bltdd43779342bd9107/blt1645b96b020bdced/64380d5d8d2c523d0da7467d/543212762.jpg'); /* Replace with your background image */
    background-size: cover;
    background-position: center;
    height: 350px; /* Set the height of the hero section */
  }

  .contents-hero-section{
    position: relative;
    z-index: 1;
  }
  
  .hero-overlay-center-operation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
    color: white;
    height: 350px; 
    z-index: 1;
  }
  
  .center-hero-section .display-4 {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .center-hero-section .lead {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  .oparation-contents{
   
  }
  
  