.star {
    font-size: 1.5rem;
    margin-right: 5px;
}

.star.filled {
    color: gold;
}

.star.empty {
    color: lightgray;
}

.button-design-1{
    background-color: rgb(4, 165, 58,  0.2);
    padding: 10px;
    width: 200px;
}

/* HomeTour.css */
.testimonial {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial footer {
    margin-top: 10px;
    font-style: italic;
    color: #28a745;
  }
  
  .button-design-1 {
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    color: #28a745;
    display: inline-flex;
    align-items: center;
  }
  
  .button-design-1:hover {
    color: #218838;
  }

  .packages-section {
    background-color: #f8f9fa;
}

.package-image {
    height: 200px;
    object-fit: cover;
}

h2 {
    font-weight: bold;
    color: #343a40;
}

button {
    font-weight: bold;
}

  