.rolling-home .hero-overlay-rolling {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }
  
  .feature-box {
    padding: 20px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    transition: transform 0.2s, box-shadow 0.2s;
    min-height: 250px;
  }
  
  .feature-box:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  