/* Hero Section */


/* .hero-section {
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
} */


/* Container styling */

.container {
    margin-top: 50px;
}

.container h2 {
    color: rgb(0, 0, 0);
    text-align: left;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}


/* Table styling */

.table {
    margin-top: 20px;
    border-collapse: separate;
    border-spacing: 0;
}

h1 {
    font-size: 3rem;
}


/* .table th {
    background-color: green;
    color: #fff;
    text-align: center;
    padding: 10px;
} */

.table td {
    text-align: left;
    padding: 8px;
    vertical-align: middle;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
    background-color: #f1f1f1;
}


/* Adjust alignment for rows */

.row {
    margin: 0;
    padding: 0;
}


/* Add some padding to the container */

.container .row {
    padding: 15px;
}