.hero-overlay {
    background-image: url('https://pachamamafoodsng.com/wp-content/uploads/2024/03/Importance-of-Supporting-Local-Farmers-with-Every-Meal_.jpg'); /* Replace with your desired image */
    background-size: cover;
    background-position: center;
    color: white;
    height: 400px; 
    position: relative;
  }
  
  /* Dark overlay on top of the background image */
  .hero-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    z-index: 1; /* Ensure overlay is on top of the background image */
  }
  
  .hero-overlay .content {
    position: relative;
    z-index: 2; /* Content is above the overlay */
  }
  
  /* Text and Button Styling */
  .hero-overlay h1,
  .hero-overlay p {
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 3rem;
  }
  
  button {
    font-size: 1.2rem;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  /* Feature Boxes */
  .feature-box {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    min-height: 200px;
  }
  
  .feature-box h4 {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .feature-box p {
    margin-top: 5px;
  }
  
  .features-section-home-agro{
    margin-top: 20px;
    margin-top: 20%;
    color: black;
  }