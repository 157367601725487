.login-screen-container {
    margin-top: 10px;

}

.login-form {
    border-radius: 20px;
    text-align: center;
}

/* .form-data {} */

.input-Box {
    max-width: 90%;
    height: 50px;
    box-sizing: border-box;
    margin: 5px;
    border-radius: 20px;
    padding: 25px;
    outline: none;
    border: none;
    background-color: aliceblue;
}

.login-button {
    width: 250px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    border: none;
    background-color: rgb(4, 61, 2);
    color: white;
    font-weight: 600;
}

@media only screen and (max-width: 480px) {
    .login-screen-container {
        margin-top: 10px;


    }
}

@media only screen and (max-width: 768px) {
    .login-screen-container {
        margin-top: 10px;

    }
}

.password-group {
    position: relative;
}

.eye-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
}

.select-one-Box {
    min-width: 90%;
    height: 50px;
    box-sizing: border-box;
    margin: 5px;
    border-radius: 20px;
    outline: none;
    border: none;
    background-color: aliceblue;
    padding-left: 20px;
}

.errorBox{
    height: auto;
    box-sizing: border-box;
    border-radius: 5px;
    min-width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    /* background-color: rgb(117, 11, 11); */
    color: rgb(190, 17, 17);
}