
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.social-links {
    margin-top: 10px;
}

.social-links a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
}

.social-links a:hover {
    text-decoration: underline;
}
