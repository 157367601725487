.management-resources {
    margin: 20px;
  }
  
  .resource-group-section,
  .resource-section {
    margin-bottom: 20px;
  }
  
  input,
  select {
    padding: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  button {
    padding: 8px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  h3 {
    margin-bottom: 10px;
  }
  
  h4 {
    margin-top: 10px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  li {
    margin: 5px 0;
  }
  