.item-data-table {
    width: 100%;
    border-collapse: collapse;
    /* Ensure borders don't double */
    margin-bottom: 20px;
}

.item-data-table th,
.item-data-table td {
    border: 1px solid #ddd;
    /* Adds border to table cells */
    padding: 8px;
    text-align: left;
}

.item-data-table th {
    background-color: #f2f2f2;
    /* Optional: Adds background color to header */
}

.item-data-table tr:hover {
    background-color: #f1f1f1;
    /* Optional: Adds hover effect for rows */
}

.item-data-table td {
    vertical-align: top;
}


.bom-heading {
    text-align: left;
}

.image-container-bom {
    margin-top: 50px;
}

.imgs-area {
    width: 100%;
    height: 40vh;
    box-sizing: border-box;
    border-radius: 20px;

}

.imgs-mini {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 10vh;
    box-sizing: border-box;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 20px;
}

.item-imgs {
    object-fit: cover;
    width: 100%;
    height: 10vh;
    box-sizing: border-box;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
}

.image-slider {
    object-fit: cover;
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
}

.image-clicked {
    object-fit: cover;
    width: 100%;
    height: 40vh;
    box-sizing: border-box;
    border-radius: 20px;
}

.item-data-list {
    width: 100%;
}

/* .item-data-container {} */

.data-container {
    width: 100%;
}

.product-info-card {
    margin-top: 0.2%;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    /* Full viewport height */
}

.ytv {
    width: 80%;
    /* Adjust width to make it smaller */
    max-width: 850px;
    /* Set a max width for the video */
    aspect-ratio: 16 / 9;
    /* Maintain YouTube video aspect ratio */
    border: 1px solid #ccc;
    /* Optional: Add a border for styling */
    border-radius: 8px;
    /* Optional: Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Add shadow */
}


.bom-container {
    display: flex;
    margin-bottom: 12%; 
}


@media (max-width: 768px) {
    .bom-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .instructions {
        text-align: justify;
    }

    .product-info-card {
        margin: 10px;
    }
}

@media (max-width: 480px) {
    .bom-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .product-info-card {
        margin: 10px;
    }

    .instructions {
        text-align: justify;
    }
}


.tableRow {
    box-sizing: border-box;
    /* background-color: rgb(136, 252, 165); */
    background-color: #a6dbc3;
}

.chain1,
.chain2,
.chain3,
.chain4,
.chain5,
.chain6 {
    cursor: pointer;
    margin: 20px;
    transition: transform 0.3s ease;
    /* Smooth transition for scaling */

}

.chain1:hover,
.chain2:hover,
.chain3:hover,
.chain4:hover,
.chain5:hover,
.chain6:hover {
    transform: scale(1.1);
    /* Scale up the element on hover */
}

.container {
    font-family: Arial, sans-serif;
}

.bold-heading {
    font-weight: bold;
}

.bold-text {
    font-weight: bold;
}


.product-description {
    font-size: 15px;
    

}

.d-flex   {
    font-size: 15px;
    margin-left: 4.5px; 
}

.custom-paragraph {
    font-size: 15px;
    height: auto;
}

.custom-paragraph1 {
    font-size: 15px;
}

.item-data-table2 {
    font-size: 15px;
}

.table-font {
    font-size: 15px;
}

.table-font1 {
    font-size: 15px;
}

.table-font2 {
    font-size: 15px;
}

.table-font3 {
    font-size: 15px;
}

.mt-5 {
    background-color: transparent; /* Ensure no background if empty */
    padding: 0; /* Remove spacing for empty sections */
  }
  